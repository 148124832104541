import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = "https://hrportal.zerokb.app";

export const createRole = createAsyncThunk(
  "roles/createRole",
  async (roleData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/role/create-role`,
        roleData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data.role;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchRoles = createAsyncThunk(
  "roles/fetchRoles",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/role/get-roles`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateRoleName = createAsyncThunk(
  "roles/updateRoleName",
  async ({ id, roleName }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/role/update-role-name/${id}`,
        { roleName },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data.role;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateRoleStatus = createAsyncThunk(
  "roles/updateRoleStatus",
  async ({ id, status }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/role/update-role-status/${id}`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data.role;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteRole = createAsyncThunk(
  "roles/deleteRole",
  async (id, { rejectWithValue }) => {
    try {
      await axios.delete(`${BASE_URL}/role/delete-role/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  roles: [],
  status: "idle",
  error: null,
};

const roleSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createRole.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createRole.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.roles.push(action.payload);
      })
      .addCase(createRole.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "Failed to create role";
      })
      .addCase(fetchRoles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.roles = Array.isArray(action.payload) ? action.payload : [];
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "Failed to fetch roles";
      })
      .addCase(updateRoleName.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateRoleName.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.roles.findIndex(
          (role) => role._id === action.payload._id
        );
        if (index !== -1) {
          state.roles[index] = action.payload;
        }
      })
      .addCase(updateRoleName.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "Failed to update role name";
      })
      .addCase(updateRoleStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateRoleStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.roles.findIndex(
          (role) => role._id === action.payload._id
        );
        if (index !== -1) {
          state.roles[index] = action.payload;
        }
      })
      .addCase(updateRoleStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "Failed to update role status";
      })
      .addCase(deleteRole.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.roles = state.roles.filter((role) => role._id !== action.payload);
      })
      .addCase(deleteRole.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "Failed to delete role";
      });
  },
});

export default roleSlice.reducer;
