import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Login from "./Pages/Login.js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import EmployeePanel from "./Pages/EmployeePanel.js";
import AdminPanel from "./Pages/AdminPanel.js";
import { restoreAuth } from "./redux/AuthSlics.js"; // Correct the import path if necessary
import PrivateRoute from "./Components/PrivateRoute.js";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // Restore authentication state from localStorage on app load
    dispatch(restoreAuth());
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        {/* Protect EmployeePanel and AdminPanel routes */}
        <Route
          path="/employeepanel/*"
          element={
            <PrivateRoute>
              <EmployeePanel />
            </PrivateRoute>
          }
        />
        <Route
          path="/adminpanel/*"
          element={
            <PrivateRoute>
              <AdminPanel />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
