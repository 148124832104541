import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children }) => {
  const { token } = useSelector((state) => state.auth); // Get token and loading state from Redux

  if (!token) {
    return <Navigate to="/" />;
  } else {
    return children;
  }
};

export default PrivateRoute;
