import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateRoleName, fetchRoles } from "../redux/RoleSlice"; // Adjust the import path as needed

const EditRoleModal = ({ isOpen, onClose, role, onUpdateRole }) => {
  const [roleName, setRoleName] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (role) {
      setRoleName(role.roleName);
    }
  }, [role]);

  const handleUpdate = async () => {
    try {
      await dispatch(updateRoleName({ id: role._id, roleName })).unwrap();
      // Trigger a fetchRoles action to refresh the list of roles
      dispatch(fetchRoles());
      onClose();
    } catch (error) {
      console.error("Failed to update role name:", error);
    }
  };

  if (!isOpen || !role) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-96">
        <h2 className="text-xl font-bold mb-4">Edit Role</h2>
        <div className="mb-4">
          <label className="block text-gray-700 font-semibold mb-2">
            Role Name
          </label>
          <input
            type="text"
            value={roleName}
            onChange={(e) => setRoleName(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded"
            placeholder="Enter role name"
          />
        </div>
        <div className="flex justify-end space-x-4">
          <button
            className="bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
            onClick={handleUpdate}
          >
            Update Role
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditRoleModal;
