import React from "react";
import Sidebar from "../Components/SideBar";
import { Routes, Route } from "react-router-dom";
import Navbar from "../Components/Navbar";
import ApplyLeave from "../Components/ApplyLeave";
import Profile from "../Components/Profile";
import EmployeeDashboard from "../Components/EmployeeDashboard";
import PrivateRoute from "../Components/PrivateRoute"; // Import PrivateRoute

const EmployeePanel = () => {
  return (
    <>
      <div className="flex flex-row bg-gray-100">
        <Sidebar role="employee" />
        <div className="flex-grow ">
          <Navbar />
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <EmployeeDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/apply-leave"
              element={
                <PrivateRoute>
                  <ApplyLeave />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default EmployeePanel;
