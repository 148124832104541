import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createRole } from "../redux/RoleSlice"; // Adjust the path according to your project structure

const CreateRoleModal = ({ isOpen, onClose }) => {
  const [roleName, setRoleName] = useState("");
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.roles); // Adjust according to your state structure

  const handleCreateRole = () => {
    if (roleName.trim()) {
      dispatch(createRole({ roleName }))
        .unwrap()
        .then(() => {
          setRoleName("");
          onClose(); // Close the modal on success
        })
        .catch((err) => {
          console.error("Failed to create role:", err); // Log the error for debugging
        });
    } else {
      // Handle validation error if needed
      alert("Role name cannot be empty");
    }
  };

  if (!isOpen) return null; // Don't render the modal if it's not open

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
      role="dialog"
      aria-labelledby="create-role-modal-title"
      aria-modal="true"
    >
      <div className="bg-white rounded-lg p-6 w-96">
        <h2 id="create-role-modal-title" className="text-xl font-bold mb-4">
          Create New Role
        </h2>
        {error && <div className="text-red-600 mb-4">{error}</div>}{" "}
        {/* Display any errors */}
        <div className="mb-4">
          <label
            htmlFor="role-name"
            className="block text-gray-700 font-semibold mb-2"
          >
            Role Name
          </label>
          <input
            id="role-name"
            type="text"
            value={roleName}
            onChange={(e) => setRoleName(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded"
            placeholder="Enter role name"
            disabled={status === "loading"}
            aria-describedby="role-name-helper"
          />
          <p id="role-name-helper" className="text-gray-500 text-xs mt-1">
            Enter the name of the new role.
          </p>
        </div>
        <div className="flex justify-end space-x-4">
          <button
            className="bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400"
            onClick={onClose}
            disabled={status === "loading"}
          >
            Cancel
          </button>
          <button
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
            onClick={handleCreateRole}
            disabled={status === "loading" || !roleName.trim()}
          >
            {status === "loading" ? "Creating..." : "Create Role"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateRoleModal;
