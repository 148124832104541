import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "./Api";

// const BASE_URL = "http://localhost:5000";

// const axiosInstance = axios.create({
//   baseURL: BASE_URL,
//   headers: {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${localStorage.getItem("token")}`,
//   },
// });

// Thunk to fetch leave requests
export const fetchLeaveRequests = createAsyncThunk(
  "leaveRequests/fetchLeaveRequests",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/leave/get-leave-requests");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Thunk to update leave request status
export const updateLeaveRequestStatus = createAsyncThunk(
  "leaveRequests/updateLeaveRequestStatus",
  async ({ id, status }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/leave/leave-request/${id}/status`,
        { status }
      );
      return { id, status };
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Thunk to fetch annual leave data
export const fetchLeaveData = createAsyncThunk(
  "leaveRequests/fetchLeaveData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/leave/user/leave-data");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Thunk to fetch leave history
export const fetchLeaveHistory = createAsyncThunk(
  "leaveRequests/fetchLeaveHistory",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/leave/leave-history");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Thunk to apply for leave
export const applyLeave = createAsyncThunk(
  "leaveRequests/applyLeave",
  async (leaveRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "/leave/leave-request",
        leaveRequest
      );
      return response.data.message;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const leaveRequestsSlice = createSlice({
  name: "leaveRequests",
  initialState: {
    requests: [],
    annualLeaveLeft: 0,
    leaveHistory: [],
    loading: false,
    error: null,
    successMessage: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle fetchLeaveRequests
      .addCase(fetchLeaveRequests.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLeaveRequests.fulfilled, (state, action) => {
        state.loading = false;
        state.requests = action.payload;
      })
      .addCase(fetchLeaveRequests.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle updateLeaveRequestStatus
      .addCase(updateLeaveRequestStatus.fulfilled, (state, action) => {
        const { id, status } = action.payload;
        const existingRequest = state.requests.find(
          (request) => request._id === id
        );
        if (existingRequest) {
          existingRequest.status = status;
        }
      })
      .addCase(updateLeaveRequestStatus.rejected, (state, action) => {
        state.error = action.payload;
      })
      // Handle fetchLeaveData
      .addCase(fetchLeaveData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLeaveData.fulfilled, (state, action) => {
        state.loading = false;
        state.annualLeaveLeft = action.payload.totalAnnualLeave;
      })
      .addCase(fetchLeaveData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle fetchLeaveHistory
      .addCase(fetchLeaveHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLeaveHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.leaveHistory = action.payload;
      })
      .addCase(fetchLeaveHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle applyLeave
      .addCase(applyLeave.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(applyLeave.fulfilled, (state, action) => {
        state.loading = false;
        state.successMessage = action.payload;
      })
      .addCase(applyLeave.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default leaveRequestsSlice.reducer;
