import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile, updateProfilePicture } from "../redux/EmployeeSlice";
import UpdateProfileModal from "./UpdateProfileModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";

const Profile = () => {
  const dispatch = useDispatch();
  const {
    profile: user,
    loading,
    error,
  } = useSelector((state) => state.employees);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchProfile());
  }, [dispatch]);

  const handleProfilePictureUpdate = async (formData) => {
    try {
      await dispatch(updateProfilePicture(formData)).unwrap();
      // After updating, re-fetch the profile to ensure the UI is updated
      dispatch(fetchProfile());
      setIsModalOpen(false);
    } catch (error) {
      console.error("Failed to update profile picture:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!user) {
    return <div>No user data available</div>;
  }

  return (
    <div className="mt-20 bg-gray-100 flex flex-col items-center py-10">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-2xl text-center">
        <div className="relative flex flex-col items-center">
          <div className="relative">
            <img
              src={user.profilePicture}
              alt="Profile"
              className="w-24 h-24 rounded-full object-cover mb-4"
            />
            <button
              onClick={() => setIsModalOpen(true)}
              className="absolute bottom-0 right-0 transform translate-x-1/4 translate-y-1/4 bg-white rounded-full p-1 hover:bg-gray-200"
            >
              <FontAwesomeIcon
                icon={faCamera} // Use camera icon here
                className="text-blue-600 w-6 h-6"
              />
            </button>
          </div>
          <h2 className="text-2xl font-bold mt-2">
            {user.firstName} {user.lastName}
          </h2>
        </div>
        <div className="mt-6 text-left">
          <h3 className="text-xl font-semibold mb-4 text-center">
            Personal Information
          </h3>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-600 font-semibold">Email</label>
              <p className="text-gray-800">{user.email}</p>
            </div>
            <div>
              <label className="block text-gray-600 font-semibold">Role</label>
              <p className="text-gray-800">{user.role}</p>
            </div>
            <div>
              <label className="block text-gray-600 font-semibold">
                Joining Date
              </label>
              <p className="text-gray-800">
                {new Date(user.joiningDate).toLocaleDateString()}
              </p>
            </div>
            <div>
              <label className="block text-gray-600 font-semibold">
                Date of Birth
              </label>
              <p className="text-gray-800">
                {new Date(user.dateOfBirth).toLocaleDateString()}
              </p>
            </div>
            <div>
              <label className="block text-gray-600 font-semibold">
                Address
              </label>
              <p className="text-gray-800">{user.address}</p>
            </div>
            <div>
              <label className="block text-gray-600 font-semibold">
                Employee ID
              </label>
              <p className="text-gray-800">{user.employeeId}</p>
            </div>
            <div>
              <label className="block text-gray-600 font-semibold">
                Status
              </label>
              <p className="text-gray-800">{user.status}</p>
            </div>
            <div>
              <label className="block text-gray-600 font-semibold">
                Annual Leave Days Left
              </label>
              <p className="text-gray-800">{user.annualLeaveDays}</p>
            </div>
          </div>
        </div>
      </div>
      <UpdateProfileModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleProfilePictureUpdate} // Pass the new handler
      />
    </div>
  );
};

export default Profile;
