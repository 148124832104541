import React, { useState, useEffect } from "react";
import LeaveFormModal from "./LeaveFromModal";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useSelector, useDispatch } from "react-redux";
import { fetchLeaveData, fetchLeaveHistory } from "../redux/LeaveRequestslice";

const ApplyLeave = () => {
  const dispatch = useDispatch();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [flashMessage, setFlashMessage] = useState("");

  // Access the state from the Redux store
  const annualLeaveLeft = useSelector(
    (state) => state.leaveRequests.annualLeaveLeft
  );
  const leaveHistory = useSelector((state) => state.leaveRequests.leaveHistory);
  const totalAnnualLeave = 10;

  useEffect(() => {
    // Dispatch thunks to fetch leave data and history
    dispatch(fetchLeaveData());
    dispatch(fetchLeaveHistory());
  }, [dispatch]);

  // Logic to hide the flash message after 3 seconds
  useEffect(() => {
    if (flashMessage) {
      const timer = setTimeout(() => {
        setFlashMessage("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [flashMessage]);

  return (
    <div className="flex flex-col items-center justify-center p-6 bg-gray-100">
      {flashMessage && (
        <div className="mb-4 bg-green-500 text-white px-4 py-2 rounded">
          {flashMessage}
        </div>
      )}
      <div className="mb-6 text-center">
        <h2 className="text-2xl font-bold mb-4">Annual Leave Tracker</h2>
        <div className="flex justify-center items-center mb-4">
          <div style={{ width: 150, height: 150 }}>
            <CircularProgressbar
              value={annualLeaveLeft}
              maxValue={totalAnnualLeave}
              text={`${annualLeaveLeft} days`}
              styles={buildStyles({
                textSize: "16px",
                pathColor: annualLeaveLeft > 2 ? "green" : "red",
                textColor: annualLeaveLeft > 2 ? "green" : "red",
                trailColor: "#d6d6d6",
              })}
            />
          </div>
        </div>
        <p className="text-lg">
          You have <strong>{annualLeaveLeft}</strong> out of{" "}
          <strong>{totalAnnualLeave}</strong> annual leave days left.
        </p>
      </div>
      <button
        onClick={() => setModalIsOpen(true)}
        className="bg-blue-500 text-white px-4 py-2 rounded"
      >
        Apply Leave
      </button>
      <LeaveFormModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        setFlashMessage={setFlashMessage} // Pass down the function to set flash message
      />
      <div className="mt-10 w-full">
        <h2 className="text-xl font-bold mb-4">Leave Request History</h2>
        <div className="overflow-auto bg-white shadow-md rounded-lg">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Start Date
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  End Date
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Reason
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Leave Type
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {leaveHistory.map((leave) => (
                <tr key={leave._id}>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {new Date(leave.startDate).toLocaleDateString()}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {new Date(leave.endDate).toLocaleDateString()}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {leave.reason}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {leave.leaveType}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <span
                      className={`relative inline-block px-3 py-1 font-semibold leading-tight ${
                        leave.status === "approved"
                          ? "text-green-900 bg-green-200"
                          : leave.status === "declined"
                          ? "text-red-900 bg-red-200"
                          : "text-yellow-900 bg-yellow-200"
                      }`}
                    >
                      <span
                        aria-hidden="true"
                        className="absolute inset-0 opacity-50 rounded-full"
                      ></span>
                      <span className="relative">{leave.status}</span>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ApplyLeave;
